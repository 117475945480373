<div class="header">
    <h1>{{ team?.name }}</h1>
</div>

<div class="content" *ngIf="team">
    <div class="card">
        <div class="card-header">
            <h2>General information</h2>
        </div>

        <div class="section">
            <div class="entry">
                <span class="body-strong">Team name</span>
                <span>{{ team.name }}</span>
            </div>
            <div class="entry">
                <span class="body-strong">Owner</span>
                <div class="owner-info">
                    <span>{{ team.owner.fullName }}</span>
                    <span>{{ team.owner.email }}</span>
                    <nuc-button-secondary (click)="openTransferOwnershipDialog()"
                                          text="Transfer ownership"></nuc-button-secondary>
                </div>
            </div>
        </div>
    </div>


    <div class="card">

        <div class="card-header">
            <h2>Team usage</h2>
            <nuc-button-secondary icon="nucicon_edit" (click)="onEditTeamUsageClicked()"></nuc-button-secondary>
        </div>

        <div class="contract-info" *ngIf="team.contract">
            <h3>General contract information</h3>
            <p class="contract-details">
                <nuc-icon-button icon="nucicon_calendar"></nuc-icon-button>
                <span class="body-strong">Start date</span>
                <span>{{ team.contract.startDate | RLDatePipe:dateFormats.DEFAULT }}</span>
            </p>

        </div>

        <div class="section">
            <h3>Seats</h3>
            <nuc-progress-bar [value]="(team.userCount / team.userLimit) * 100"></nuc-progress-bar>
            <div class="section-bottom">
                <p class="body-strong">Used seats</p>
                <p>{{ team.userCount }} {{ team.userCount === 1 ? 'user' : 'users' }} out
                    of {{ team.userLimit }} <span class="body-strong grey">total seats</span>
                </p>
            </div>
        </div>

        <div class="section">
            <h3>Storage</h3>
            <nuc-status-bar [value]="(team.storageUsed / team.storageLimit) * 100"></nuc-status-bar>
            <div class="section-bottom">
                <p class="body-strong">Used storage</p>
                <p>
                <span [class.danger]="(team.storageUsed / team.storageLimit) * 100 > 90">
                    {{ team.storageUsed | filesize }}
                </span>
                    out of {{ team.storageLimit | filesize }} <span class="body-strong grey">total storage</span>
                </p>
            </div>
        </div>

        <div class="section">
            <h3>Content items</h3>
            <nuc-status-bar
                [value]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100"></nuc-status-bar>
            <div class="section-bottom">
                <p class="body-strong">Created Content items - in current period</p>
                <p>
                <span
                    [class.danger]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100 > 90">
                    {{ team.publicationItemCreatedInCurrentContractPeriod }}
                </span>
                    of {{ team.publicationItemLimit }} <span class="body-strong grey">total creations</span>
                </p>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <h2>Population</h2>
        </div>

        <div class="population-option">
            <p>Products</p>
            <nuc-button-secondary icon="nucicon_add" (click)="populateProductsClicked()"></nuc-button-secondary>
        </div>

        <div class="population-option">
            <p>Assets</p>
            <nuc-button-secondary icon="nucicon_add" (click)="populateAssetsClicked()"></nuc-button-secondary>
        </div>

        <div class="population-option">
            <p>Campaign</p>
            <nuc-button-secondary icon="nucicon_add" (click)="populateCampaignClicked()"></nuc-button-secondary>
        </div>

        <div class="population-option">
            <p>InDesign Library</p>
            <nuc-button-secondary icon="nucicon_add" (click)="populateInDesignLibraryClicked()"></nuc-button-secondary>
        </div>

    </div>

    <div class="card">
        <div class="card-header">
            <h2>Import customer data</h2>
        </div>

        <div class="population-option">
            <p>Products</p>
            <nuc-button-secondary icon="nucicon_add"
                                  (click)="importCustomerProductDataClicked()"></nuc-button-secondary>
        </div>
    </div>

</div>

<div class="statistics">

    <div class="card">
        <div class="card-header">
            <h2>Statistics</h2>
        </div>
        <form class="selection" [formGroup]="formGroupStatistics">

            <nuc-dropdown [items]="collections" formControlName="collection" [nullOption]="false"></nuc-dropdown>

            <nuc-dropdown [items]="periods" formControlName="period" [nullOption]="false"></nuc-dropdown>
        </form>
        <canvas baseChart
                width="700"
                height="300"
                [data]="barChartData"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [type]="barChartType">
        </canvas>
    </div>

</div>
