import {Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {TeamService} from '../../../api/services/team.service';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {map, switchMap} from 'rxjs/operators';
import {CONTRACT_OPTIONS, TeamModel, TeamPatchModel} from '../../../models/response/team.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../classes/toaster.class';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';

export interface IEditTeamUsageData {
    team: TeamModel;
}

class TeamFormatForm {
    userLimit: FormControl<number>;
    storageLimit: FormControl<number>;
    publicationItemLimit: FormControl<number>;
    contract: FormGroup<{
        period: FormControl<DropdownItem<string>>
        startDate: FormControl<Date>
    }>
}

@Component({
    selector: 'om-edit-team-usage',
    templateUrl: './edit-team-usage.component.html',
    styleUrls: ['./edit-team-usage.component.scss']
})
export class EditTeamUsageComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup<TeamFormatForm>;
    private saveConfig: ButtonConfig;

    public contractPeriodOptions: DropdownItem<string>[] = Object.entries(CONTRACT_OPTIONS)
        .map(([key, label]) => new DropdownItem(label, key));

    constructor(private teamService: TeamService,
                private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IEditTeamUsageData) {
    }

    public ngOnInit(): void {
        this.initModalButtons();
        this.initForm();
    }

    private initForm(): void {
        this.formGroup = new FormGroup<TeamFormatForm>({
            userLimit: new FormControl(this.modalData.team.userLimit, Validators.required),
            storageLimit: new FormControl(this.modalData.team.storageLimit, Validators.required),
            publicationItemLimit: new FormControl(this.modalData.team.publicationItemLimit, Validators.required),
            contract: new FormGroup({
                period: new FormControl({
                    value: this.contractPeriodOptions.find((dataFieldOption) =>
                        dataFieldOption.getValue() === this.modalData.team.contract?.period),
                    disabled: !!this.modalData.team.contract?.period
                }, Validators.required),
                startDate: new FormControl(
                    { value: this.modalData.team.contract?.startDate, disabled: !!this.modalData.team.contract?.startDate },
                    Validators.required
                )
            })
        });

        this.formGroup.statusChanges.pipe(
            map((status) => status === 'VALID'),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((valid) => this.saveConfig.disabled = !valid);
    }

    private initModalButtons(): void {
        const cancelConfig = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', false, false, true);

        const cancelAction = new FullModalActionModel(cancelConfig);
        const saveAction = new FullModalActionModel(this.saveConfig);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.pipe(
            switchMap(() => {
                const formGroupValue = this.formGroup.getRawValue();
                const patchBody = new TeamPatchModel(
                    undefined,
                    formGroupValue.userLimit,
                    formGroupValue.storageLimit,
                    formGroupValue.publicationItemLimit,
                    formGroupValue.contract.period.getValue() as CONTRACT_OPTIONS,
                    formGroupValue.contract.startDate,
                )
                return this.teamService.patchTeam(this.modalData.team._id, patchBody);
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (result) => {
                Toaster.success('Team usage is successfully updated!');
                this.fullModalService.close(result);
            },
            error: (error) => Toaster.handleApiError(error.message)
        });
        this.fullModalService.setModalActions([cancelAction, saveAction]);
    }
}
