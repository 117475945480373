import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {ITableAction, ITableItem} from '@relayter/rubber-duck';

export enum CONTRACT_OPTIONS {
    YEAR = 'Year',
    MONTH = 'Month'
}

export class ContractModel {
    @autoserialize public period: CONTRACT_OPTIONS;
    @autoserializeAs(Date) public startDate: Date;
}

export class TeamModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(UserModel) public owner: UserModel;
    @autoserialize public disabled: boolean;
    @autoserialize public userLimit: number;
    @autoserialize public userCount: number;
    @autoserialize public permissions: string[] = [];
    @autoserialize public storageLimit: number;
    @autoserialize public publicationItemLimit: number;
    @autoserialize public storageUsed: number;
    @autoserialize public publicationItemCreatedInCurrentContractPeriod: number;
    @autoserializeAs(ContractModel) public contract: ContractModel;

    public actions: ITableAction[];

    get status() {
      return this.disabled ? 'Disabled' : 'Active';
    }
}

export class TeamPatchModel {
    public disabled: boolean;
    public userLimit: number;
    public storageLimit: number;
    public publicationItemLimit: number;
    public contract: ContractModel;
    constructor(
        disabled?: boolean,
        userLimit?: number,
        storageLimit?: number,
        publicationItemLimit?: number,
        period?: CONTRACT_OPTIONS,
        startDate?: Date)
    {
        this.disabled = disabled;
        this.userLimit = userLimit;
        this.storageLimit = storageLimit;
        this.publicationItemLimit = publicationItemLimit;
        this.contract = {
            period,
            startDate
        }
    }
}
